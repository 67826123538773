:root {
    --main-color: rgb(10,184,183);
    --main-color-10-opacity: rgba(10,184,183, 0.1);
    --main-color-30-opacity: rgba(10,184,183, 0.3);
    --main-color-60-opacity: rgba(10,184,183, 0.6);
}

/*:root {*/
/*    --main-color: rgb(142, 187, 208);*/
/*    --main-color-10-opacity: rgba(142, 187, 208, 0.1);*/
/*    --main-color-30-opacity: rgba(142, 187, 208, 0.3);*/
/*    --main-color-60-opacity: rgba(142, 187, 208, 0.6);*/
/*}*/

*, html {
    scroll-behavior: smooth !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #232323;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
    max-width: 100%;
    display: block;
    height: auto;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}

h2 {
    margin-bottom: 25px;
}

a, a:visited {
    color: darkblue;
    text-decoration: none;
    transition: .3s linear;
}

a:hover, a:focus {
    color: var(--main-color);
}

*::selection {
    background-color: var(--main-color);
    color: #fff;
}

address {
    font-style: normal;
}

ul {
    padding: 0;
}

ul > li {
    list-style: none;
}

li:not(:last-child) {
    margin-bottom: 10px;
}

.container {
    padding-right: 20px;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.box {
    border-radius: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 40px #0000001a;
}

.home-page-blocks {
    margin-top: -30px;
    padding-bottom: 30px;
}

.menu-page__wrapper {
    padding-top: 25px;
}

.menu-page__sidebar h3 {
    line-height: 1;
    margin-bottom: 0;
    margin-top: 0;
}

.menu-page__sidebar h3 a {
    display: block;
    padding: 12px 15px;
    border-radius: 15px;
    margin-bottom: 5px;
    transition: .3s linear;
    cursor: pointer;
    color: #232323;
}

.menu-page__sidebar h3.active a, .menu-page__sidebar h3 a:hover {
    background-color: var(--main-color-10-opacity);
    color: var(--main-color);
}

.menu-page__sidebar::-webkit-scrollbar {
    width: 2px;
    background-color: var(--main-color-10-opacity);
}

.menu-page__sidebar::-webkit-scrollbar-thumb {
    background-color: var(--main-color-60-opacity);
    border-radius: 15px;
}

.menu-page__content::-webkit-scrollbar {
    width: 4px;
    background-color: var(--main-color-10-opacity);
}

.menu-page__content::-webkit-scrollbar-thumb {
    background-color: var(--main-color-60-opacity);
    border-radius: 15px;
}

@media screen and (max-width: 767px) {
    body::-webkit-scrollbar {
        width: 2px;
        background-color: var(--main-color-10-opacity);
    }

    body::-webkit-scrollbar-thumb {
        background-color: var(--main-color-60-opacity);
        border-radius: 15px;
    }

    body::-webkit-scrollbar {
        width: 4px;
        background-color: var(--main-color-r10-opacity);
    }

    body::-webkit-scrollbar-thumb {
        background-color: var(--main-color-60-opacity);
        border-radius: 15px;
    }

    .menu-page__sidebar {
        position: fixed;
        z-index: 80;
        top: 81px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
        opacity: 0;
        transform: translateX(100%);
        transition: 0.4s ease-in-out;
    }

    .menu-page__sidebar.active {
        opacity: 1;
        transform: translateX(0);
    }

    .menu-page__sidebar h3 a {
        padding: 12px 15px 14px;
        font-size: 22px;
    }
}


@media screen and (min-width: 768px) {

    .container {
        padding-right: 15px;
        padding-left: 15px;
        width: 720px;
    }

    .home-page-blocks {
        display: flex;
        justify-content: space-between;
    }

    .menu-page__wrapper {
        display: grid;
        grid-template-columns: 250px 1fr;
        column-gap: 30px;
        padding-top: 30px;
    }

    .menu-page__content, .menu-page__sidebar {
        height: calc(100vh - 135px);
        overflow: auto;
        padding-right: 10px;
    }
}

@media screen and (min-width: 1200px) {

    .container {
        width: 920px;
    }

    .box {
        border-radius: 30px;
        padding: 30px;
    }

    .menu-page__wrapper {
        padding-top: 40px;
    }
}
