.dishes-sublist {

  &:not(:first-child) {
    padding-top: 90px;

    @media (min-width: 768px) {
      padding-top: 65px;
    }
  }

  &:first-child {
    @media (max-width: 767px) {
      padding-top: 50px;
    }
  }

  .desc {
    color: var(--main-color);

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h2 {
    margin-bottom: 10px;
  }
}

.dish-wrapper {
  margin-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--main-color);
  position: relative;
  min-height: 100px;

  @media (min-width: 768px) {
    min-height: 165px;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}