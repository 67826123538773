.about {
  @media (min-width: 768px) {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  @media (min-width: 1200px) {
    min-width: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }

  .clock-icon {
    transform: scale(1.05);
  }

  .phone-icon {
    transform: scale(0.95);
  }

  p {
    margin-bottom: 20px;
    margin-top: 0;
    line-height: 1.4;

    @media (min-width: 768px) {
      margin-bottom: 25px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__desc {
    margin-bottom: 15px;
    color: grey;
  }

  .working-hours {
    p {
      margin-bottom: 0;
    }
  }

  &__address-item {
    font-size: 1em;
    color: #232323;
    font-weight: 500;
    display: flex;

    a, a:visited {
      color: #232323;

      &:hover, &:focus {
        color: var(--main-color);
      }
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }
  }

  &__address-item:not(:last-child) {
    margin-bottom: 20px;
  }

  &__delivery {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
      transition: .3s ease-in-out;
      transform: scale(1);
    }

    a:hover, a:focus {
      img {
        transform: scale(1.1);
      }
    }

    a:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__social-links {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    a {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: .3s ease-in-out;
        transform: scale(1);
      }

      &:hover, &:focus {
        img {
          transform: scale(1.1);
        }
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}