.special-categories {

  ul {
    margin-top: 0;

    li:not(:last-child) {
      margin-bottom: 15px;
    }

    h3 {
      display: flex;
      align-items: center;
      font-weight: 500;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  
  &.horizontal {

    h3 {
      font-size: 1em;
      font-weight: 500;
    }
    
    & > ul {
      display: flex;
      margin-bottom: 0px;
      overflow: auto;
      padding-top: 2px;
      padding-bottom: 2px;

      @media (min-width: 768px) {
        margin-bottom: 70px;
      }
    }

    li {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 15px;
        margin-bottom: 0;

        @media (min-width: 768px) {
          margin-right: 30px;
        }
      }

      a, a:visited {
        display: flex;
        align-items: center;
        flex-direction: column;

        &:hover, &:focus, &.active {
          .icon-wrapper {
            background-color: var(--main-color-60-opacity);

            img {
              transform: scale(1.05);
            }
          }

          span {
            color: var(--main-color);
          }
        }
      }

      .icon-wrapper {
        width: 65px;
        height: 65px;
        border-radius: 15px;
        background-color: transparent;
        border: 1px solid var(--main-color-60-opacity);
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: .3s linear;

        img {
          width: 35px;
          height: 35px;
          transform: scale(1);
          transition: .3s ease-out;
        }
      }

      span {
        font-size: 0.9em;
        font-weight: 500;
        max-width: 85px;
        text-align: center;
        transition: .3s linear;
        color: #232323;

        @media (min-width: 768px) {
          max-width: 100px;
        }
      }
    }
  }
}