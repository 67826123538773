.categories {

  & > ul > li {
    margin-bottom: 0;
  }

  .subcategories-list {
    padding-left: 15px;
    max-height: 0;
    transition: .6s ease;
    overflow: hidden;
    opacity: 0;

    @media (min-width: 768px) {
      padding-left: 30px;
    }

    &.active {
      opacity: 1;
      max-height: 1500px;
      padding: 15px 15px 30px 15px;

      @media (min-width: 768px) {
        padding: 10px 0px 10px 20px;
      }

      @media (min-width: 1200px) {
        padding: 10px 0px 10px 30px;
      }
    }

    li {
      transition: .3s linear;
      cursor: pointer;
      font-weight: 500;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &:not(:last-child) {
        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      a, a:visited {
        color: #232323;
        display: block;
      }

      &.active, &:hover {


        a, a:visited {
          color: var(--main-color);
        }
      }
    }
  }
}