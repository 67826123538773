.dish-info {
  transition: .3s ease-out;
  margin-right: 135px;

  @media (min-width: 576px) {
    margin-right: 215px;
  }

  @media (min-width: 768px) {
    margin-right: 180px;
  }

  @media (min-width: 1200px) {
    margin-right: 250px;
  }

  h3 {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .weight, .desc {
    color: grey;
    font-size: 0.85em;
    margin-bottom: 5px;
    margin-top: 0;

    strong {
      color: #222;
    }
  }

  .price {
    line-height: 1;
    font-weight: 500;
    transition: .3s linear;

    display: block;
    margin-top: 20px;

    font-size: 1.1em;
  }

  button {
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
    //outline: none;
    //box-shadow: none;
    //border: 1px solid var(--main-color);
    //background-color: #fff;
    //transition: .3s linear;
    //border-radius: 15px;
    //padding: 10px 30px;
    //margin-top: 20px;
    //cursor: pointer;

    //&:hover, &:focus {
    //  border-color: var(--main-color);
    //  background-color: var(--main-color);
    //
    //  .price {
    //    color: #fff;
    //  }
    //}
  }
}

.dish-image {
  border-radius: 16px;
  height: 80px;
  max-height: calc(100% - 25px);
  width: 120px;
  object-fit: cover;
  transition: .3s ease-out;
  cursor: pointer;
  transform: scale(1);
  position: absolute;
  top: 0;
  right: 0;

  @media (min-width: 576px) {
    width: 200px;
    height: 130px;
    max-height: unset;
  }

  @media (min-width: 768px) {
    width: 150px;
    height: 100px;
  }

  @media (min-width: 1200px) {
    width: 215px;
    height: 140px;
  }

  &:hover {
    transform: scale(1.05);
    filter: brightness(1.1);
  }

  &.clicked {
    width: 100%;
    float: right;
    max-height: 395px;
    height: 57vw;

    @media (min-width: 768px) {
      height: 250px;
    }

    @media (min-width: 1200px) {
      height: 400px;
    }

    &:hover {
      transform: scale(1);
    }

    & + .dish-info {
      padding-top: calc(57vw + 15px);

      @media (min-width: 768px) {
        padding-top: 265px;
      }

      @media (min-width: 1200px) {
        padding-top: 415px;
      }
    }
  }
}

.dish-no-photo {
  display: none;
}