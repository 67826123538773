.home-menu {

  margin-bottom: 20px;
  height: fit-content;

  @media (min-width: 768px) {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    min-width: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;

    li {

      a, a:visited {
        color: #232323;
        display: block;
        width: 100%;
        padding: 13px 25px 16px;
        border: 1px solid #ccc;
        border-radius: 15px;
        font-weight: 500;
        font-size: 1.2em;
        background-color: #fff;
        position: relative;

        &:hover, &:focus {
          background-color: var(--main-color);
          color: #fff;
          border-color: var(--main-color);

          &:after {
            border-color: #fff;
            transform: translate(5px, -50%) rotate(45deg);
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 25px;
          transform: translate(0, -50%) rotate(45deg);
          width: 11px;
          height: 11px;
          border-top: 2px solid #ccc;
          border-right: 2px solid #ccc;
          transition: .3s ease-in-out;
        }

      }
    }
  }
}