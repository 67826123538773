.home-header {
  width: 100%;
  position: relative;
  margin-bottom: 0px;

  &__bg {
    width: 100%;
    height: 190px;
    object-fit: cover;

    @media (min-width: 576px) {
      height: 280px;
    }
  }

  &__logo-box {
    display: flex;
    align-items: center;
    transform: translateY(-50%);

    &:hover, &:focus {
      h2 {
        color: var(--main-color);
      }

      img {
        transform: scale(1.05);
      }
    }
  }

  &__logo {
    margin-right: 15px;

    img {
      max-height: 60px;
      width: auto;
      border-radius: 15px;
      transition: .3s ease-in-out;
    }
  }

  address {
    color: grey;
    font-weight: 500;
  }

  h2 {
    margin-bottom: 5px;
    color: #232323;
    transition: .3s linear;
  }
}