.menu-header {
  width: 100%;
  background-color: #fff;
  padding: 15px 0;
  border-bottom: 1px solid #DEDEDE;

  @media (max-width: 767px) {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  @media (min-width: 768px) {
    box-shadow: 0 2px 40px #0000001a;
    border: none;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    margin-bottom: 5px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  address {
    color: grey;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
  }

  &__logo {
    transition: .35s ease-in-out;
    margin-right: 15px;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 15px;
      transform: scale(1);

      @media (min-width: 768px) {
        width: 55px;
        height: 55px;
      }
    }
  }

  .wrapper-menu {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 330ms ease-out;
    
    @media (min-width: 768px) {
      display: none;
    }
  }

  .wrapper-menu.open {
    transform: rotate(-45deg);
  }

  .line-menu {
    background-color: var(--main-color);
    border-radius: 5px;
    width: 100%;
    height: 3px;
  }

  .line-menu.half {
    width: 50%;
  }

  .line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
  }

  .open .line-menu.start {
    transform: rotate(-90deg) translateX(1px);
  }

  .line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
  }

  .open .line-menu.end {
    transform: rotate(-90deg) translateX(-2px);
  }
}