.error {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fcfcfc;

  h2 {
    font-size: 2rem;
    color: #414141;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 0;
  }
}